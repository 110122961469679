<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-09-07 14:31:45
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:45:43
 * @FilePath: /dataview-viewer-test/src/views/newSecond/components/todoCom/WarringDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog
      title="预警信息"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
      :before-close="onClose">
      <div class="type-label">警告等级：
      </div>
      <span class="status" :class="flterStatus(warringData.warn_level).class">{{ flterStatus(warringData.warn_level).text}}</span>
      <div class="type-label">警告类型：
      </div>
      <span class="status">【{{ warringData.warn_type || '其他' }}预警】</span>
      <div class="type-label">警告内容：
      </div>
      <div class="title">{{ warringData.warn_content }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button v-if="getUserAuth()" type="primary" @click="onSave">已 读</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data'
export default {
  components: {},
  props: {
    dialogVisible:{
      type:Boolean,
      default:false,
    },
    warringData:{
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(()=> {
          done();
        })
        .catch(() => {});
    },
    onClose(){
      this.$emit('onClose');
    },
    onSave(){
      //TODO 等待已读接口
      let params = {
        __method_name__: 'updateData',
        transcode: 0,
        data_id: this.warringData.id,
        object_uuid:'object635201835e212',
        view_uuid:'view63a85fe0ba34c',
        read_type: 1
      }
      dataInterface(params).then(res=>{
        console.log(res)
        this.$emit('onSave');
      })
      
    },
    flterStatus(text = '') {
        if(text.length === 0) {
            return {
            text: '-',
            class: ''
            }
        }
        /* 增加判断紫色预警，紫色预警为智慧工地相关，没有颜色但是有类型的情况 */
        const str = text[0]
        const classHash = {
            '黄': 'y-status',
            '橙': 'o-status',
            '蓝': 'b-status',
            '红': 'r-status'
        }
        return {
            text: classHash[str] ? `${str}色预警` : str ? '紫色预警' : '其他预警',
            class: classHash[str] ? classHash[str] : str ? 'p-status' : 'other-status'
        }
    },
    getUserAuth() {
      const userInfo = this.$GetUserInfo();
      return this.warringData?.alert_people && (+userInfo.id === +this.warringData.alert_people) && !this.warringData.read_type
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
.type-label{
    border-left: 3px solid var(--themeColor) !important;
    padding: 0 10px;
    line-height: 20px;
    font-size: 14px;
    margin: 15px 0 !important;
    font-weight: 600;
    text-align: left;
}
.r-status{
    background: #FFE6E6;
    color: #FF5C5C;
}
.o-status{
    background: #FFE7D4;
    color: #FF8826;
}
.b-status{
    background: #E0E8FF;
    color: #5C87FF;
}
.y-status{
    background: hsla(40, 100%, 50%, .2);
    color: #FFAA00;
}
.other-status{
    background: rgba(77, 77, 77, .2);
    color: #777;
}
.p-status{
    background: #F8E0FF;
    color: #CA64EA;
}
// .title{
//     flex-grow: 1;
//     width: 10px;
//     margin-left: 2px;
//     height: 22px;
//     font-size: 14px;
//     font-family: PingFangSC-Regular, PingFang SC;
//     font-weight: 400;
//     color: #2A2F3D;
//     line-height: 22px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
</style>