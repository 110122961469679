var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:{
 'app-box': +_vm.systemConfig.theme_type !== 4,
 'four-news': +_vm.systemConfig.theme_type === 4
}},[(+_vm.systemConfig.theme_type !== 4)?[(_vm.validData && Array.isArray(_vm.validData) && _vm.validData.length)?_vm._l((_vm.validData),function(item,index){return _c('div',{key:index,staticClass:"container",style:(item.listData.length && item.is_show ? '' : 'display:none')},[_c('div',{staticClass:"header"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(item.shortcut_name))])]),_c('div',{staticClass:"content grid-class",class:{
            'grid-content':
              _vm.validData && Array.isArray(_vm.validData) && _vm.validData.length,
          }},[(
              item.listData &&
              Array.isArray(item.listData) &&
              item.listData.length
            )?_vm._l((item.listData),function(items,index){return _c('div',{key:index,staticClass:"content-item-box",on:{"click":function($event){return _vm.handleAppClick(items)}}},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"content-icon"},[(!items.shortcut_icon)?_c('i',{staticClass:"iconfont iconzhuomianyingyong"}):(items.shortcut_icon.indexOf('el-icon-') === 0)?_c('i',{class:[items.shortcut_icon]}):(
                      items.shortcut_icon_bg &&
                      items.shortcut_icon_bg !== 'transparent'
                    )?_c('i',{staticClass:"iconfont-item",class:items.shortcut_icon,style:({ background: items.shortcut_icon_bg })}):(!item.shortcut_icon.includes(' '))?_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + _vm.getIcon(items.shortcut_icon)}})]):_vm._e()]),_c('div',{staticClass:"content-text"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(items.shortcut_name))]),(items.shortcut_desc)?_c('p',{staticClass:"item-desc"},[_vm._v(" "+_vm._s(items.shortcut_desc)+" ")]):_vm._e()])])])}):_vm._e()],2)])}):_c('el-empty',{attrs:{"description":"暂无数据","image-size":80}})]:[_c('div',{staticClass:"title"},[_vm._v(" 我的应用 ")]),(_vm.validData && Array.isArray(_vm.validData) && _vm.validData.length)?_c('div',{staticClass:"list"},_vm._l((_vm.validData),function(item,index){return _c('div',{key:index,staticClass:"item",style:(item.listData.length && item.is_show ? '' : 'display:none')},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.shortcut_name))]),(
            item.listData &&
            Array.isArray(item.listData) &&
            item.listData.length
          )?_c('div',{staticClass:"item-content grid-class"},_vm._l((item.listData),function(items,index){return _c('div',{key:index,staticClass:"item-data",on:{"click":function($event){return _vm.handleAppClick(items)}}},[_c('div',{staticClass:"data-icon"},[(!items.shortcut_icon)?_c('i',{staticClass:"iconfont iconzhuomianyingyong"}):(items.shortcut_icon.indexOf('el-icon-') === 0)?_c('i',{class:[items.shortcut_icon]}):(
                  items.shortcut_icon_bg &&
                  items.shortcut_icon_bg !== 'transparent'
                )?_c('i',{staticClass:"iconfont-item",class:items.shortcut_icon}):(!item.shortcut_icon.includes(' '))?_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + _vm.getIcon(items.shortcut_icon)}})]):_vm._e()]),_c('div',{staticClass:"date-text"},[_c('div',{staticClass:"date-title"},[_vm._v(" "+_vm._s(items.shortcut_name)+" ")]),_c('div',{staticClass:"data-desc"},[_vm._v(" "+_vm._s(items.shortcut_desc)+" ")])])])}),0):_vm._e()])}),0):_c('el-empty',{attrs:{"description":"暂无数据","image-size":80}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }