<!--
 * @Description: 新版二屏
 * @Author: luocheng
 * @Date: 2022-06-17 18:23:20
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-07 16:17:23
-->
<template>
  <div class="new-second" :style="styleObj">
    <template v-if="showContent">
      <ThemeFour
        v-if="+this.systemConfig.theme_type === 4"
        :hiddenAppBtn="true"
        @search="drawer = true"
      />
      <Header v-else :style="{ zoom: zoom }"></Header>
      <article
        class="new-second-main"
        v-if="targetArchi"
        :style="{ zoom: zoom }"
      >
        <GridComponent
          :class="{ 'four-style': this.systemConfig.theme_type == 4 }"
        ></GridComponent>
      </article>
    </template>
    <el-drawer title="全局搜索" :visible.sync="drawer" direction="rtl">
      <SearchDrawer />
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dataInterface } from "@/apis/data";
import ThemeFour from "../appEntry/components/top/ThemeFour.vue";
import GridComponent from "./components/index/GridComponent";
import SearchDrawer from "./components/index/SearchDrawer.vue";
import Header from './components/index/Header.vue';

export default {
  name: "NewSecond",
  components: {
    GridComponent,
    ThemeFour,
    SearchDrawer,
    Header
  },
  data() {
    return {
      showContent: false,
      defaultImage:
        "https://static.bimcc.com/openim/lt-bg6D2BCF737560A023231C9552B54D3CDA.png",
      zoom: 1,
      drawer: false,
    };
  },
  computed: {
    ...mapState(["systemConfig", "targetArchi", "needHistoryArchi"]),
    styleObj() {
      return {
        "background-image":
          this.systemConfig?.theme_type === 4
            ? "url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/58d4319d350913a6a4529088c6646d93.png)"
            : `url(${this.systemConfig?.second_bg_img || this.defaultImage})`,
      };
    },
  },
  async created() {
    this.zoom = 1;
    this.$store.commit("setCurrentMenu", null);
    if (this.needHistoryArchi) {
      const historyArchi = await this.getHistoryArchi();
      if (historyArchi) {
        this.onToggleArchi(historyArchi.archi_type, 1);
        this.showContent = true;
        this.$store.commit("getHistoryArchi", false);
        return;
      }
    }
    this.getArchi();
  },
  methods: {
    /**
     * @desc: 获取可用组织架构
     */
    getArchi() {
      // this.$loading();
      dataInterface({
        __method_name__: "globalFunctionCall",
        typeName: "Archi",
        type: "value",
        funcName: "AllArchi",
      })
        .then((res) => {
          if (!res || res.status !== 200) return false;
          this.archiData = res.data.data;
          const { company = [], project = [] } = res.data.data;
          localStorage.setItem("myCompany", JSON.stringify(company));
          localStorage.setItem("myProjects", JSON.stringify(project));
          let targetArchi = null;
          let sessionArchi = localStorage.getItem("targetArchi");
          if (sessionArchi) {
            // 从缓存中的组织架构进行获取
            try {
              targetArchi = JSON.parse(sessionArchi);
            } catch (err) {
              console.log("缓存解析失败！");
            }
          }
          // 不存在缓存或解析失败
          if (!targetArchi) {
            // 默认获取第一个
            targetArchi = company?.[0] || project?.[0] || null;
            const archiList = company.concat(project);
            if (archiList.length) {
              for (let i = 0; i < archiList.length; i++) {
                if (archiList[i].last_login_date) {
                  if (!targetArchi) {
                    targetArchi = archiList[i];
                  } else {
                    try {
                      const beginData = new Date(
                        archiList[i].last_login_date.replace("-", "/")
                      );
                      const endData = new Date(
                        targetArchi.last_login_date.replace("-", "/")
                      );
                      if (beginData - endData > 0) {
                        targetArchi = archiList[i];
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                }
              }
            }
            if (!targetArchi) {
              this.$message.error("暂无可用架构");
              return;
            }
            targetArchi = {
              ...targetArchi,
              archiId: targetArchi.archi_id,
              archiType:
                targetArchi.id.indexOf("companys") > -1 ? "company" : "project",
              id: targetArchi.id,
              company_id: targetArchi.company_id || "",
            };
          }
          if (targetArchi?.id) {
            this.onToggleArchi(targetArchi?.id, 2);
          }
          window.localStorage.setItem(
            "currentProjectInfo",
            JSON.stringify(targetArchi)
          );
          this.showContent = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @desc: 获取登录历史记录
     */
    getHistoryArchi() {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: "globalFunctionCall",
          typeName: "Archi",
          type: "value",
          funcName: "UserHistoryArchi",
        })
          .then((res) => {
            if (!res) {
              reject(null);
              return;
            }
            const { companys = [], projects = [] } = res?.data?.data || {};
            let archiArr = [];
            if (Array.isArray(projects) && projects.length) {
              archiArr = archiArr.concat(projects);
            }
            if (Array.isArray(companys) && companys.length) {
              archiArr = archiArr.concat(companys);
            }
            if (archiArr.length) {
              let lastLoginArchi = null;
              for (let i = 0; i < archiArr.length; i++) {
                if (archiArr[i].last_login_date) {
                  if (lastLoginArchi === null) {
                    lastLoginArchi = archiArr[i];
                  } else {
                    let beginData = new Date(
                      archiArr[i].last_login_date.replace("-", "/")
                    );
                    let endData = new Date(
                      lastLoginArchi.last_login_date.replace("-", "/")
                    );
                    if (beginData - endData > 0) {
                      lastLoginArchi = archiArr[i];
                    }
                  }
                }
              }
              resolve(lastLoginArchi);
              return;
            } else {
              resolve(null);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
            reject(null);
          });
      });
    },
    /**
     * @desc: 获取组织架构的信息（请求架构列表的信息有问题，需要用此函数请求架构数据）
     */
    onToggleArchi(id, from) {
      console.log(from, "-----222---");
      dataInterface({
        __method_name__: "globalFunctionCall",
        typeName: "LoginFuncClass",
        type: "behavior",
        funcName: "ToggleArchBehavior",
        payload: {
          __external_company_type: localStorage.getItem("userType"),
          archi_id: id,
          archi_table:
            id.indexOf("companys") > -1 ? "org_companys" : "org_projects",
        },
      })
        .then((res) => {
          if (!res) {
            return false;
          }
          const targetArchi = {
            ...res.data.data,
            archiId: res.data.data.archi_id,
            archiType: id.indexOf("companys") > -1 ? "company" : "project",
            id: res.data.data.data_id,
            modelApproval: ["https://bim.xwjgpt.com/"].includes(
              process.env.VUE_APP_V3_HOST
            )
              ? true
              : false, //模型上传需要审批的项目地址
            company_id: res.data.data.data.company_id || "",
          };
          // console.log('setTargetArchi---1aaaaaaaaaa')
          // console.log(targetArchi, '----==++++++++++++++---------');
          this.$store.commit("setTargetArchi", {
            targetArchi,
            a: 10,
          });
          this.$store.commit("setCacheArchi", targetArchi);
          window.localStorage.setItem(
            "currentProjectInfo",
            JSON.stringify(targetArchi)
          );
          localStorage.setItem("userRoles", res.data.data.permissions || "");
          if (res?.data?.data?.userInfo) {
            const userInfo = res?.data?.data?.userInfo || {};
            let roleNames = [];
            if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
              userInfo?.roles.forEach((ele) => {
                if (ele?.name) {
                  roleNames.push(ele.name);
                }
              });
            }
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                roleName: roleNames.join(),
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @desc: 设置当前跳转的应用
     */
    setCurrentApp() {
      const app = JSON.parse(sessionStorage.getItem("appList"))[0];
      this.$store.commit("setCurrentApp", app);
      const appData = app.app_orign_data;
      if (!appData || !appData.length) {
        this.$message.error(" 应用配置错误，请联系管理员!");
        return;
      }
      const indexObj = this.getIndexPath(appData);
      if (!indexObj) {
        this.$message.error("应用暂未配置有效首页!");
        return;
      }
      this.$store.commit("setAppIndexMenu", indexObj);
      this.$store.commit("setAppNavHistory", {
        origin: 16,
        history: [indexObj],
      });
      // 保存当前的应用
      this.$store.commit("setCurrentApp", app);
    },
  },
  mounted() {
    console.log(this.systemConfig.theme_type, "---4---");
  },
};
</script>

<style lang="less" scoped>
.new-second {
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-width: 860px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  // background-image: url('https://static.bimcc.com/openim/lt-bg6D2BCF737560A023231C9552B54D3CDA.png');
  background-size: cover;
  background-size: 100% 100%;
  .new-second-main {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  ::v-deep(.top-right-box) {
    display: flex;
    justify-content: flex-end;
  }
  .four-style {
    ::v-deep(.vue-grid-item) {
      border: none;
      border-radius: 16px;
      overflow: hidden;
      .drawing-board {
        > :nth-of-type(2) {
          // background: linear-gradient(
          //   180deg,
          //   rgba(255, 77, 0, 0.15) 0.2%,
          //   rgba(255, 114, 0, 0.15) 23.39%,
          //   rgba(247, 171, 25, 0.03) 61.62%
          // );
          background: #fff;
        }
      }
    }
  }
}
::v-deep(.el-drawer) {
  .el-drawer__header {
    padding: 16px 12px 16px 20px;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);
  }
  width: 500px !important;
  .el-drawer__body {
    padding: 20px;
  }
}
</style>
