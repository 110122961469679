<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-04-03 14:35:35
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-03 15:12:21
 * @FilePath: /dataview-viewer-test/src/views/newSecond/components/buildView/Index.vue
 * @Description: 
-->
<template>
  <div class="build-view">
    <h4 class="title">建设看板</h4>
    <div class="main">
      <div class="content" v-if="show">
        <div class="content-item-box" v-for="(item,index) in infoList" :key="index">
          <div class="content-item" :style="'background:' + item.background" @click="handleClick(item)">
            <div class="item-left">
              <p class="item-title">{{item.title}}</p>
              <div class="sub-title-box">
                <p class="sub-title" v-if="item.rate" :style="'color:' + item.color">{{item.rate}}</p>
                <p class="sub-title" v-if="item.rank" :style="'color:' + item.color">{{item.rank}}</p>
              </div>
            </div>
            <div class="item-right">
              <img :src="item.icon" style="display:block;" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openUrl } from '@/utils/tools';
import { mapState } from 'vuex';
import { formRequest } from "@/apis/data/form";
import { infoList } from '@/views/newSecond/components/statistic/config.js'

export default {
  name: 'BuildView',
  data() {
    return {
      show: false,
      infoList: infoList
    }
  },
  computed: {
    ...mapState(['targetArchi'])
  },
  watch: {
    targetArchi: {
      handler() {
        this.getBuildBoardData()
        // this.getDynamicList()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 获取建设看板（利率和名次）
     */
     getBuildBoardData(){
      this.show = false
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object62b90a0c70a9e',
        view_uuid: 'view62b90ebe25920',
        __method_name__: 'dataList',
        transcode: 0
      }
      formRequest(method,url,param).then(res=>{
        if(res.data.code === 200){
          let data = res.data?.data?.data
          if(data && data.length ){
            const {investment_completion_rate = 0,progress_completion_rate = 0,safety_evaluation = 0,quality_evaluation = 0,safety_evaluation_rank = 0,quality_evaluation_rank = 0} = data[0]
            this.infoList[0].rate = investment_completion_rate ? investment_completion_rate + '%' : ''
            this.infoList[1].rate = progress_completion_rate ? progress_completion_rate + '%' : ''
            this.infoList[2].rate = safety_evaluation ? safety_evaluation + '分' : ''
            this.infoList[3].rate = quality_evaluation ? quality_evaluation + '分' : ''
            this.infoList[2].rank = safety_evaluation_rank ? 'NO.' + safety_evaluation_rank : ''
            this.infoList[3].rank = quality_evaluation_rank ?  'NO.' + quality_evaluation_rank : ''
          }

        }
        // console.log(this.infoList)
        this.show = true
      })
    },
    /**
     * @desc: 点击快捷方式(建设看板)
     * @param {Object} item
     */
     handleClick(item){
      const {
        path = '',
        query = {},
        isScreen = false,
        // originData = {}
      } = item;
      if (!path) {
        this.$message.error('暂未配置目标页面!');
        return;
      }
      if (isScreen) {
        // window.open(path.indexOf('http') === 0 ? path : window.origin + path);
        openUrl(path.indexOf('http') === 0 ? path : window.origin + path , item.name)
        return;
      }
      this.$router.push({
        path,
        query
      })
    }
  }
}
</script>

<style lang="less" scoped>
.build-view {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  .title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #2A2F3D;
    letter-spacing: 1px;
  }
  .main{
    height: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    .content{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content-item-box{
        width: 48%;
        padding: 8px 0;
        .content-item{
          padding: 23px 20px 19px 20px;
          background: #fff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-left{
            flex: 1;
            padding-right: 16px;
            box-sizing: border-box;
            overflow: hidden;
            .item-title{
              height: 22px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2A2F3D;
              line-height: 22px;
            }
            .item-title{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .sub-title-box{
              // width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              
              .sub-title{
                height: 32px;
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #3881FF;
                line-height: 32px;
              }
            }
          }
          .item-right{
            width: 48px;
            height: 48px;
            img{
              width: 48px;
              height: 48px;
            }
          }
        }
      }
      
    }
  }
}
</style>