<template>
  <div
    v-if="+systemConfig.theme_type !== 4"
    class="todo-list-warp"
    v-loading="loading"
  >
    <div
      class="list-item"
      v-for="(item, index) in list"
      :key="index"
      @click.stop="handleClick(item)"
    >
      <div class="info-warp">
        <div class="info-icon" :class="filterIcon(item.object_name).class">
          <span>{{ filterIcon(item.object_name).text }}</span>
        </div>
        <div class="info-text-warp">
          <div class="line-1">
            <div class="line-title">
              <div class="info-title">
                {{ item.object_name }} | {{ item.title }}
              </div>
            </div>

            <p class="status" :class="filterStatus(item.status).class">
              {{ filterStatus(item.status).label }}
            </p>
          </div>
          <div class="line-2">
            <p class="project-name">项目：{{ item.archi_name }}</p>
            <p class="time-status">{{ filterTime(item.endDate).time }}</p>
          </div>
        </div>
      </div>
    </div>
    <Empty
      v-if="list.length === 0"
      description="暂无数据"
      :image-size="100"
    ></Empty>
  </div>
  <div class="four-todo" v-else>
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click.stop="handleClick(item)"
    >
      <div class="top">
        <div class="left">
          <div class="name">{{ item.object_name }}</div>
          <div class="status" :class="filterStatus(item.status).class">
            {{ filterStatus(item.status).label }}
          </div>
        </div>
        <div class="right">
          <img
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/af3ccbee493ceb8b99120f0946980aa4.png"
            alt=""
          />
          {{ item.from_user_name }}>我
        </div>
      </div>
      <div class="bottom">
        <div class="project">项目：{{ item.archi_name }}</div>
        <div class="date">{{ item.startDate }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import { Empty } from "element-ui";
import { mapState } from "vuex";
export default {
  components: {
    Empty,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["systemConfig"]),
  },
  data() {
    return {};
  },
  methods: {
    handleClick(item) {
      console.log(item);
      let data = {
        event: "showDetailModal",
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name: item.object_name,
      };
      window.parent.postMessage(data, "*");
      return;
    },
    filterIcon(text) {
      if (!text || text.length === 0) {
        return {
          text: "-",
          class: "",
        };
      }
      const str = text[0];
      const classHash = {
        安: "s-icon",
        质: "q-icon",
        进: "p-icon",
      };
      return {
        text: str,
        class:
          classHash[str] ||
          classHash[
            Object.keys(classHash)[
              Math.floor(Math.random() * Object.keys(classHash).length)
            ]
          ],
      };
    },
    filterStatus(status) {
      switch (status + "") {
        case "-1": {
          return {
            label: "退回",
            class: "w-status",
          };
        }
        case "0": {
          return {
            label: "待初审",
            class: "d-status",
          };
        }
        case "5": {
          return {
            label: "反馈完成",
            class: "s-status",
          };
        }
        default: {
          return {
            label: "审批中",
            class: "p-status",
          };
        }
      }
    },
    filterTime(time) {
      const targetDate = new Date(time);
      if (isNaN(targetDate.getTime())) {
        return {
          time: "-",
          className: "",
          str: "",
        };
      } else {
        return this.timeFormat(time);
      }
    },
    // 时间格式化
    timeFormat(time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const diffTime = this.nowDateObj.getTime() - targetDate.getTime();
      if (diffTime > 24 * 3600 * 1000) {
        return {
          time: `逾期${Math.floor(diffTime / (24 * 3600 * 1000))} 天`,
          class: "w-status",
          str: "已滞后",
        };
      }
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return {
              time: `今天${format(targetDate, "HH:mm")}截止`,
              class: "s-status",
              str: "待处理",
            };
          } else if (td - nd === 1) {
            return {
              time: `明天${format(targetDate, "HH:mm")}截止`,
              class: "p-status",
              str: "待处理",
            };
          } else {
            return {
              time: `${format(targetDate, "MM-dd HH:mm")}截止`,
              class: "p-status",
              str: "待处理",
            };
          }
        } else {
          return {
            time: `${format(targetDate, "MM-dd HH:mm")}截止`,
            class: "p-status",
            str: "待处理",
          };
        }
      } else {
        return {
          time: `${format(targetDate, "yyyy-MM-dd HH:mm:ss")}截止`,
          class: "p-status",
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.todo-list-warp {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .list-item {
    width: 100%;
    display: flex;
    padding: 12px 10px;
    box-sizing: border-box;
    align-items: flex-end;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      border-radius: 3px;
    }
    .info-warp {
      flex-grow: 1;
      width: 100px;
      display: flex;
      .info-icon {
        flex-shrink: 0;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 6px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
      }
      .q-icon {
        background: rgba(90, 204, 120, 0.2);
        color: #5acc78;
      }
      .s-icon {
        background: rgba(255, 170, 74, 0.2);
        color: #ffaa4a;
      }
      .p-icon {
        background: rgba(51, 187, 255, 0.2);
        color: #33bbff;
      }
      .other-icon {
        background: #f1f1f1;
      }
      .info-text-warp {
        margin-left: 12px;
        flex-grow: 1;
        width: 100px;
        .line-1 {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          justify-content: space-between;
          .line-title {
            flex: 1;
            overflow: hidden;
            position: relative;
            height: 22px;
            margin-right: 8px;
          }
          .info-title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #292f3d;
            line-height: 22px;
            display: inline-block;
            white-space: nowrap;
            position: absolute;
            left: 0px;
            &:hover {
              animation: 5s todayScroll linear infinite;
            }
          }

          .status {
            flex-shrink: 0;
            margin-left: 8px;
            height: 20px;
            padding: 0 8px;
            box-sizing: border-box;
            border-radius: 12px;
            line-height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            white-space: normal;
          }
          .w-status {
            background: #fff1f1;
            color: #e0452d;
          }
          .s-status {
            background: #e1f5e7;
            color: #21a35d;
          }
          .p-status {
            color: #0088cc;
            background: #e0f4ff;
          }
          .d-status {
            color: #007d79;
            background: #e1f7f6;
          }
        }
        .line-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2px;
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6d768f;
          line-height: 18px;
          .project-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            width: 10px;
          }
          .time-status {
            flex-shrink: 0;
            white-space: nowrap;
            height: 18px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6d788f;
            line-height: 18px;
            margin-right: 5px;
          }
        }
      }
    }
    // .time-status{
    //     flex-shrink: 0;
    //     white-space: nowrap;
    //     height: 18px;
    //     font-size: 12px;
    //     font-family: PingFangSC-Regular, PingFang SC;
    //     font-weight: 400;
    //     color: #6D788F;
    //     line-height: 18px;
    //     margin-right: 5px;
    // }
  }
}
@keyframes todayScroll {
  0% {
    left: 0%;
  }

  100% {
    left: -35%;
  }
}
.four-todo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  overflow: auto;
  width: -webkit-fill-available;
  .item {
    display: flex;
    padding: var(--spacing-6, 12px) 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3, 6px);
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #f9f6f4;
    background: var(--overall-surface-default, #fff);
    &:hover {
      border-radius: 12px;
      border: 1px solid #f9f6f4;
      background: linear-gradient(
        180deg,
        rgba(247, 171, 25, 0) 2.19%,
        rgba(242, 119, 19, 0.1) 52.39%,
        rgba(231, 0, 6, 0.1) 98.44%
      );
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .left {
        overflow: hidden;
        color: var(--text-on-surface-primary, #181b22);
        text-overflow: ellipsis;

        /* web/cn/helper/helper-03 */
        font-family: "PingFang SC";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: 0.4px;
        display: flex;
        .name {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .status {
        flex-shrink: 0;
        margin-left: 8px;
        height: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        border-radius: 12px;
        line-height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        white-space: normal;
      }
      .w-status {
        background: #fff1f1;
        color: #e0452d;
      }
      .s-status {
        background: #e1f5e7;
        color: #21a35d;
      }
      .p-status {
        color: #0088cc;
        background: #e0f4ff;
      }
      .d-status {
        color: #007d79;
        background: #e1f7f6;
      }
      .right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text-on-surface-tertiary, #707786);
      width: 100%;
      /* web/cn/helper/helper-02 */
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      letter-spacing: 0.4px;
    }
  }
}
</style>
