<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-12-28 15:25:34
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-04-19 16:56:56
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\schedule\index.vue
 * @Description: 南宁机场第二屏需求 进度统计
-->
<template>
  <div class="schedule-second" v-loading='loading1 || loading2'>
    <header class="theme">
      <h4>进度统计</h4>
      <i class="iconfont iconxiangyoujiantou2" @click="jumpRouter"></i>
    </header>
    <div class='schedule-body'>
      <div class='list-box' @click="jumpRouter">
        <div class='list-title title-blue'>填方完成量(汇总)  {{fillData?.progress_least}}</div>
        <div class='list-middle'>
          <div class='middle-text'>
            <el-progress v-if='!loading1 && !loading2' type="circle" :percentage='getPercentage(this.fillData?.progress_least,this.fillData?.show_fill_filish)' :width='74' color='#419FFF'/>
            <div class='text'>
              <div class='blod'>{{fillData?.progress_least}}</div>
              <div>{{fillData?.show_fill_filish}}</div>
            </div>
          </div>
          <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/1f396ad8229abe19e198c9f03d181cd2.png" class='img' >
          <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/9d7838f9acc201d4962c65f59fd0bde7.png" class='anima-img'>
        </div>
        <div class='list-foot'>场道部分（万m³）</div>
      </div>
      <div class='list-box' @click="jumpRouter">
        <div class='list-title title-yellow'>桩基完成量  {{pileData?.show_filish}}</div>
        <div class='list-middle'>
          <div class='middle-text'>
            <el-progress v-if='!loading1 && !loading2' type="circle" :percentage="getPercentage(pileData?.show_filish,pileData?.show_total)" :width='74' color='#E7A33D'/>
            <div class='text'>
              <div class='blod'>{{pileData?.show_filish}}</div>
              <div>{{pileData?.show_total}}</div>
            </div>
          </div>
          <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/9a11280724badd1749496ae16c73ba8f.png" class='img'>
          <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/251420635ae31a3c035e2f339de179da.png" class='anima-img'>
        </div>
        <div class='list-foot'>T3航站区部分（根）</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formRequest } from "@/apis/data/form";
import { Progress } from "element-ui";
export default {
  components: {
    'el-progress':Progress
  },
  props: {},
  data() {
    return {
      // 填方数据
      pileData:{
        show_filish:0,
        show_total:0
      },
      // 桩基完成量
      fillData:{
        show_fill_filish:0,
        progress_least:0
      },
      loading1:true,
      loading2:true,
    };
  },
  watch: {},
  computed: {

  },
  methods: {
    getPercentage(num1,num2){
      if( num1!==0 && num2!==0 ){
        return +((num1/num2*100).toFixed(2))
      }else {
        return 0
      }
    },
    getData(){
      const url = "api/mapi",
        method = "POST";
      const param = {
        object_uuid: "object65771f099130f",
        view_uuid: "view65884dbd415bc",
        __method_name__: "dataList",
        transcode: 0,
        type_value: "2"
      };
      formRequest(method, url, param).then(res=>{
        console.log(res);
        this.loading1 = false
        if(res.data.code===200){
          this.fillData = res.data.data[0]
        }
      })
      const param1 = {
        object_uuid: "object65771f099130f",
        view_uuid: "view657721e9dbe88",
        __method_name__: "dataList",
        transcode: 0,
        type_value: "1" 
      };
      formRequest(method, url, param1).then(res=>{
        console.log(res);
        this.loading2 = false;
        if(res.data.code===200){
          this.pileData = res.data.data[0]
        }
      })
    },
    jumpRouter() {
      this.$router.push({
        path: '/child-app-bim/schedule/masterPlan'
      });
    }
  },
  created() {},
  mounted() {
    this.getData()
  }
};
</script>
<style lang="less" scoped>
.schedule-second{
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 6px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size:16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #2a2f3d;
      letter-spacing: 1px;
      color:line
    }
    i {
      font-size: 18px;
      color: gray;
      cursor: pointer;
    }
  }
  .schedule-body{
    display:flex;
    align-items: center;
    justify-content: space-evenly;

    .list-box{
      display: flex;
      height: 206px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .list-title{
        position:relative;
        margin-left:20px;
        color: var(--Neutral-Color-Font-Regular, #606266);
        font-family: Source Han Sans CN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
      .title-blue::after{
        position: absolute;
        content:'';
        left: -20px;
        top:5px;
        border-radius:50%;
        width:12px;
        height:12px;
        background:#419FFF;
      }
      .title-yellow::after{
        position: absolute;
        content:'';
        left: -20px;
        top:5px;
        border-radius:50%;
        width:12px;
        height:12px;
        background:#E6A33E;
      }
      .list-middle{
        position: relative;
        width: 120px;
        height: 120px;
        .middle-text{
          position:absolute;
          top: calc(100% - 96px);
          left: calc(100% - 96px);
          width:74px;
          height:74px;
          display: flex;
          align-items: center;
          flex-direction: column;
          :deep(.el-progress__text){
            display:none;
          }
          .text{
            position: absolute;
            width: 100%;
            height: 100%;
            color: var(--Neutral-Color-Font-Regular,#606266);
            font-family: Source Han Sans CN;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .blod{
              color: var(--text-on-surface-primary, #181B22);
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
            }
          }
        }
        .anima-img {
          position:absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          // transform: translate(-50%, -50%); /* 根据容器大小调整位置 */
          animation: rotate 6s linear infinite; /* 无限循环旋转动画 */
        }
        .img{
          position:absolute;
          top: calc(100% - 108px);
          left: calc(100% - 108px);
          height:98px;
          width:98px
        }
      }
      .list-foot{
        color: var(--Neutral-Color-Font-Regular, #606266);
        font-family: Source Han Sans CN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}
@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(360deg); /* 初始状态 */
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg); /* 结束状态 */
    }
}
</style>